import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";

const Layout = React.lazy(() => import("./common/layout/index"));
const HomePage = React.lazy(() => import("./pages/HomePage"));
const ReviewSubmissionPage = React.lazy(
  () => import("./pages/ReviewSubmissionPage")
);
const PageNotFound = React.lazy(() => import("./pages/PageNotFound"));
function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          index
          element={
            <React.Suspense
              fallback={<div className="pageload">Loading...</div>}
            >
              <HomePage />
            </React.Suspense>
          }
        />
        <Route
          path="/review/:Id"
          element={
            <React.Suspense
              fallback={<div className="pageload">Loading...</div>}
            >
              <ReviewSubmissionPage />
            </React.Suspense>
          }
        />
        <Route
          path="*"
          element={
            <React.Suspense
              fallback={<div className="pageload">Loading...</div>}
            >
              <PageNotFound />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
